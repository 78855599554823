import get from 'lodash.get';

// === Data === //
import { legalPagesData } from '@graphql/LegalPages';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { metatags } from '@DTO/sections/Metatags';
import { legalPagesNav } from '@DTO/sections/LegalPagesNav';
import { legalPagesSections } from '@DTO/sections/LegalPagesSection';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { languagePath } from '@DTO/sections/LanguagePath';

export const LegalPagesDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const nodeQuery = legalPagesData();
    currentPageData = nodeQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    title: get(nodeEntity, 'title', ''),
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      legalPagesNav(currentPageData, 'drupalData.legalPagesLinks'),
      legalPagesSections(nodeEntity, 'fieldSectionLegal'),
    ],
  };

  return {
    ...queryData,
  };
};
