import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const legalPagesData = () => {
  const data = useStaticQuery(graphql`
    query legalPagesQuery {
      drupalData {
        legalPagesLinks: taxonomyTermQuery(
          filter: { conditions: { field: "vid", value: "menu_legal" } }
          limit: 1000
        ) {
          entities {
            ... on drupalData_TaxonomyTermMenuLegal {
              entityBundle
              name
              tid
              parent {
                entity {
                  tid
                }
              }
              description {
                processed
              }
              fieldPage {
                entity {
                  path {
                    alias
                  }
                }
              }
              tid
              uuid
            }
          }
        }
        nodeQuery(filter: { conditions: { field: "type", value: "legal_pages" } }) {
          entities {
            entityBundle
            ... on drupalData_NodeLegalPages {
              entityBundle
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldSectionLegal {
                entity {
                  ...ParagraphSectionLegal
                }
              }
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
