import React from 'react';

// === Helpers === //
import cn from 'classnames';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/sections/LegalSection/LegalSection.module.scss';

// === Types === //
import { ILegalSection, ILegalSectionData } from '@components/sections/LegalSection/LegalSection.d';

// === Components === //
import ButtonLink from '@components/common/ButtonLink/ButtonLink';

const LegalSection = ({ sections, pageTitle }: ILegalSection) => {
  return (
    <section className={cn(styles['legalSection'])}>
      <h1 className={cn(styles['legalSectionTitle'])}>{pageTitle}</h1>

      {sections.map((section: ILegalSectionData, index: number) => (
        <div key={index}>
          {section?.subtitle && (
            <h2 className={cn(styles['legalSectionSubtitle'])}>{section?.subtitle}</h2>
          )}
          <div className={cn(styles['legalSectionBody'])}>{utils.safeHtml(section?.body)}</div>
          {section?.cta?.label && (
            <ButtonLink
              label={section?.cta?.label}
              href={section?.cta?.href}
              variant={section?.cta?.variant}
            ></ButtonLink>
          )}
          {section?.disclaimer && (
            <p className={cn(styles['legalSectionDisclaimer'])}>{section?.disclaimer}</p>
          )}
        </div>
      ))}
    </section>
  );
};

export default LegalSection;
