import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import LegalPagesComponents from '@components/layout/LegalPagesComponents/LegalPagesComponents';

// === Content === //
import { LegalPagesDTO } from '../DTO/LegalPages';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface LegalPagesProps {
  pageContext?: TemplatePageContextData;
}

const LegalPages = ({ pageContext }: LegalPagesProps) => {
  const dto = LegalPagesDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(dto.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={dto.metaData}
        breadcrumbs={dto.breadcrumbs}
        stickedForm={dto.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={dto.langPath}
      >
        <LegalPagesComponents
          components={dto.components}
          slug={`/${pageContext?.slug || ''}`}
          pageTitle={dto.title}
        />
      </Layout>
    </>
  );
};

export default LegalPages;
