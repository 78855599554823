import React, { Fragment } from 'react';

// === Components === //
import LegalSection from '@components/sections/LegalSection/LegalSection';
import LegalPagesNav from '@components/common/LegalPagesNav/LegalPagesNav';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/layout/LegalPagesComponents/LegalPagesComponents.module.scss';

// === Types === //
import { PageData } from '@type/page-components.d';

const LegalPagesComponents = ({ components, pageTitle, type, slug }: PageData) => {
  return (
    <div className={cn('page-wrapper', styles['pageComponentsWrapper'])}>
      {components.map((component, index) => (
        <Fragment key={index}>
          {component && component.type && component.type !== '' && (
            <>
              {component.type === 'legal_pages_nav' && <LegalPagesNav {...component} />}
              {component.type === 'legal_pages_sections' && (
                <LegalSection {...component} pageTitle={pageTitle} />
              )}
            </>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default LegalPagesComponents;
