import React from 'react';
import { Link } from "gatsby"
import { useLocation } from '@reach/router';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/common/LegalPagesNav/LegalPagesNav.module.scss';

// === Types === //
import { ILegalPagesNav, ILegalPagesNavData } from '@components/common/LegalPagesNav/LegalPagesNav.d';

const LegalPagesNav = ({ links }: ILegalPagesNav) => {
  const location = useLocation();

  return (
    <ul className={cn(styles['legalSectionNav'])}>
      {links.map((link: ILegalPagesNavData) =>
        <li
          key={link.id}
          className={cn(styles['legalSectionNavItem'], link.link === location.pathname && styles['active'])}
        >
          <Link to={link.link}>{link.name}</Link>
        </li>
      )}
    </ul>
  );
};

export default LegalPagesNav;