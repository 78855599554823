import get from 'lodash.get';

export const legalPagesNav = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entities`, undefined);

  const mappedData = {
    type: 'legal_pages_nav',
    links: data.map(link => ({
      id: get(link, 'tid', ''),
      description: get(link, 'description.processed', ''),
      name: get(link, 'name', ''),
      parent: get(link, 'parent.entity.tid', ''),
      link: get(link, 'fieldPage.entity.path.alias', '')
    }))
  };

  return mappedData;
}