import { ctaVariants } from '@utils';
import get from 'lodash.get';

export const legalPagesSections = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}`, undefined);

  const mappedData = {
    type: 'legal_pages_sections',
    sections: data.map(section => ({
      cta: {
        variant: ctaVariants[get(section, 'entity.fieldCta[0].entity.entityBundle', 'cta_gray')],
        label: get(section, 'entity.fieldCta[0].entity.fieldLinkCta.title', ''),
        href: get(section, 'entity.fieldCtaCookiePolicy', undefined) || get(section, 'entity.fieldCta[0].entity.fieldLinkCta.url.path', '/'),
      },
      disclaimer: get(section, 'entity.fieldDisclaimer', ''),
      body: get(section, 'entity.fieldPageBody.processed', ''),
      subtitle: get(section, 'entity.fieldSubtitle', ''),
    }))
  };

  return mappedData;
}